var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "修改瑕疵",
        width: 800,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("a-form-item", { attrs: { label: "平台定义瑕疵" } }, [
                _c(
                  "div",
                  { staticClass: "w-100-w flex-start-center flex-wrap mt-10" },
                  _vm._l(_vm.remarkTagList, function(item) {
                    return _c(
                      "a-tag",
                      {
                        key: item.value,
                        attrs: {
                          color: _vm.currentRemarkTagText.includes(item.name)
                            ? "red"
                            : ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleChangeTag(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "用户自定义瑕疵" } },
                [
                  _c("a-input", {
                    staticClass: "w-600",
                    attrs: { allowClear: "", placeholder: "用户自定义瑕疵" },
                    model: {
                      value: _vm.params.customRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "customRemark", $$v)
                      },
                      expression: "params.customRemark"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "平台点评" } },
                [
                  _c("a-input", {
                    staticClass: "w-600",
                    attrs: { allowClear: "", placeholder: "平台点评" },
                    model: {
                      value: _vm.params.platformRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "platformRemark", $$v)
                      },
                      expression: "params.platformRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }